<template lang="pug">
.modal-wrapper
	Teleport(to="main")
		.reveal-overlay(
			role="dialog"
		)
			.reveal(:class="size")
				header.reveal-header
					slot(name="header")
				button.close-button(
					@click="close"
					aria-label="Close modal"
					type="button"
				)
					span(aria-hidden="true") &times;
				.reveal-body
					slot

				footer.reveal-footer
					slot(name="footer")
					.button-group.align-right.no-margin

						button.hollow.button(
							@click="close"
							aria-label="Close modal"
							type="button"
						) Close

						button.alert.button(
							v-if="deleteButton"
							:disabled="disableDeleteButton"
							aria-label="Remove"
							type="button"
							@click="remove"
						)
							fa-icon(icon="trash")
							| {{ deleteText }}
						button.hollow.button(
							v-if="confirmAlternateButton"
							aria-label="Confirm"
							type="button"
							@click="confirmAlternate"
						) {{ confirmAlternateButton }}

						button.button(
							v-if="confirmButton"
							:disabled="disableConfirmButton"
							aria-label="Confirm"
							type="button"
							@click="confirm"
						) {{ confirmText }}

				button.close-button(
					@click="close"
					aria-label="Close modal"
					type="button"
				)
					span(aria-hidden="true") &times;
</template>

<script>
export default {
	name: 'VModal',
	props: {
		size: {
			type: String,
			required: false,
			default: ''
		},
		deleteButton: {
			type: String,
			required: false,
			default: ''
		},
		confirmButton: {
			type: String,
			required: false,
			default: ''
		},
		confirmAlternateButton: {
			type: String,
			required: false,
			default: ''
		},
		disableConfirmText: {
			type: String,
			required: false,
			default: ''
		},
		disableDeleteText: {
			type: String,
			required: false,
			default: ''
		},
		disableConfirmButton: {
			type: Boolean,
			required: false,
			default: false
		},
		disableDeleteButton: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['close', 'remove', 'confirm', 'confirmAlternate'],
	computed: {
		confirmText() {
			return this.disableConfirmButton ? this.disableConfirmText : this.confirmButton;
		},
		confirmAlternateText() {
			return this.confirmAlternateButton ?? 'Confirm Alternate';
		},
		deleteText() {
			return this.disableDeleteButton ? this.disableDeleteText : this.deleteButton;
		}
	},
	mounted() {
		document.body.addEventListener('keyup', () => this.closeOnEscape());
	},
	methods: {
		close() {
			document.body.removeEventListener('keyup', this.closeOnEscape);
			this.$emit('close');
		},
		remove() {
			this.$emit('remove');
		},
		confirm() {
			this.$emit('confirm');
		},
		confirmAlternate() {
			this.$emit('confirmAlternate');
		},
		closeOnEscape() {
			if (event.key === 'Escape') {
				this.close();
			}
		}
	}
};
</script>

<style lang="scss">
	.reveal-overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1005;
		background-color: hsla(0, 0%, 3.9%, 0.45);
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: $global-padding*2 $global-padding;
		width: 100%;
		min-height: 100%;
	}

	.reveal {
		display: flex;
		flex-flow: column;
		position: relative;
		top: auto;
		margin: 0;
		padding: 0;
		border-color: var(--boxBorderColor);
		background: var(--boxBackground);
		width: 100%;
		max-width: none;

		@media print, screen and (min-width: 40em) {
			width: rem-calc(600);
			max-width: rem-calc(1200);
		}

		&.small {
			max-width: rem-calc(400);

			@media print, screen and (min-width: 40em) {
				width: 50%;
			}
		}
	}

	.reveal-header {
		margin: 0;
		padding: $global-padding;
		border-bottom: 1px solid var(--boxHighlightBorderColor);
		font-size: rem-calc(24);
		flex-shrink: 0;
	}

	.close-button {
		position: absolute;
		right: 1rem;
		top: .5rem;
		font-size: 2em;
		line-height: 1;
		z-index: 10;
		color: $dark-gray;
		cursor: pointer;

		&:hover {
			color: hsl(var(--black));
		}
	}

	.reveal-body {
		padding: $global-padding;
		background-color: var(--bodyBackground);

		> *:last-child {
			margin-bottom: 0;
		}
	}

	.reveal-footer {
		border-top: 1px solid var(--boxHighlightBorderColor);
		flex-shrink: 0;

		> .button-group {
			padding: $global-padding;
		}

		> *:last-child {
			margin-bottom: 0;
		}
	}

	label {

		.required {
			float: right;
			color: var(--error-color);
		}
	}
</style>
