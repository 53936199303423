<template lang="pug">
.v-box(:class="{collapsible: collapsible, show: !collapsed}")
	header
		h2
			button#toggle-box(
				v-if="collapsible"
				@click="toggleBox"
			)
				fa-icon(icon="sort")
			fa-icon(
				v-if="icon"
				:icon="icon"
			)
			span(v-dompurify-html="title")
		.tools(v-if="hasTools")
			slot(name="tools")
	.box-body(:class="{ 'no-padding': noPadding }")
		slot
	footer(v-if="hasFooter")
		slot(name="footer")
</template>

<script>
export default {
	name: 'VBox',
	props: {
		title: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: false,
			default: null
		},
		noPadding: {
			type: Boolean,
			required: false,
			default: false
		},
		collapsible: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			collapsed: true
		};
	},
	computed: {
		hasTools() {
			return !!this.$slots.tools;
		},
		hasFooter() {
			return !!this.$slots.footer;
		}
	},
	methods: {
		toggleBox() {
			this.collapsed = !this.collapsed;
		}
	}
};
</script>

<style lang="scss">
.v-box {
	display: flex;
	flex-direction: column;
	background-color: var(--boxBackground);
	border: 1px solid var(--boxBorderColor);
	box-shadow: 0 1px 3px hsla(var(--black), .05);
	margin-bottom: $global-margin;
	position: relative;

	&.collapsible {

		@include breakpoint(small-only) {

			#toggle-box {
				display: flex;
			}

			> header {
				border-bottom-width: 0;
			}

			> .box-body {
				display: none;
			}
		}

		&.show {

			> header {
				border-bottom-width: 1px;
			}

			> .box-body {
				display: block;
			}
		}
	}

	#toggle-box {
		color: var(--bodyColor);
		outline: none;
		position: relative;
		margin: rem-calc(-10 0 -10 -10);
		display: none;
		justify-content: center;
		align-items: center;

		svg {
			margin: 0;
		}
	}

	header {
		background-color: var(--boxHeaderBackground);
		border-bottom: 1px solid var(--boxBorderColor);
		padding: rem-calc(10);
		display: flex;
		justify-content: space-between;
		position: relative;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: rem-calc(18);
			line-height: 1;
			margin: 0;
			display: flex;
			align-items: center;

			.fa,
			img,
			svg  {
				margin-right: rem-calc(10);
				color: var(--bodyColor);
				fill: var(--bodyColor);
			}
		}

		button {
			padding: rem-calc(10);
		}

		.tools {
			display: flex;
			flex-shrink: 0;
			border-radius: rem-calc(3);
			overflow: hidden;
			border: 1px solid hsla(0, 0%, 100%, 0.1);
			box-shadow: 0 1px 5px hsla(0, 0%, 0%, 0.15);

			> span {
				padding: rem-calc(2 8);
				background: hsla(0, 0%, 100%, 0.1);
			}

			button:not(.button),
			.download {
				display: flex;
				align-items: center;
				color: var(--bodyColor);
				margin: 0;
				padding: rem-calc(0 5);
				font-size: rem-calc(12);
				font-weight: $global-weight-bold;
				transition: background .2s;
				cursor: pointer;

				&:hover {
					background: hsla(0, 0%, 100%, 0.1);
				}
			}
		}
	}

	> .box-body {
		padding: rem-calc(10);
		max-height: none;
		height: 100%;

		&.no-padding {
			padding: 0;

			> table,
			> .table-responsive table {
				margin-bottom: 0;

				> thead {
					border-width: 0 0 1px;
				}

				> tbody {
					border-width: 0;
				}

				> tfoot {
					border-width: 1px 0 0;
				}
			}
		}

		> *:last-child {
			margin-bottom: 0;
		}

		.callout {
			background-color: var(--bodyBackground);
			color: var(--bodyColor);
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}

	> footer {
		background-color: var(--boxFooterBackground);
		border-top: 1px solid var(--boxBorderColor);
		padding: 10px;

		> *:last-child {
			margin-bottom: 0;
		}
	}

	> table {

		> thead {
			border-width: 0 0 1px;
		}

		> tbody {
			border-width: 0;
		}

		> tfoot {
			border-width: 1px 0 0;
		}
	}

	> footer {
		background-color: var(--boxFooterBackground);
		border-top: 1px solid var(--boxBorderColor);
		padding: 10px;
	}

	.empty {
		padding: rem-calc(10);
		font-style: italic;
		text-align: center;
	}
}
</style>
