<template lang="pug">
main.environment-border#container(
	v-if="environmentLoaded"
	:class="{ [getEnvironment()]: true, push: pushSidebar,'no-auth': noAuthorization, loading: isLoading}"
)
	TheSidebar(
		v-if="requiresAuthorization"
		@hide-sidebar="hideSidebar"
		@tenant-changed="setTenantId"
	)
	#content-wrap(@click="hideSidebar")
		header#content-header
			button#toggle-sidebar(@click.stop="showSidebar")
				fa-icon(icon="bars")
			h1 {{ $route.meta.title }}

		#content
			.system-alert(
				v-for="alert in alerts"
				:key="alert.id"
				:class="alert.severity"
			)
				.content
					h4 {{ alert.title }}
					p {{ alert.description }}

			router-view
			TheLoading

	TheFeedback(v-if="requiresAuthorization")

	notifications(
		group="main"
		classes="ksat-notification"
		position="bottom center"
		animation-type="velocity"
	)

	svg(display="none")
		symbol#svg-system
			path(d="M6.89697024,6.64186047 L8.16363691,5.37209302 L7.44697024,4.68837209 L6.19697024,5.94186047 L3.41363691,3.23953488 C3.32241126,3.14597556 3.19591409,3.09302326 3.06363691,3.09302326 C2.93135972,3.09302326 2.80486255,3.14597556 2.71363691,3.23953488 C1.06722694,4.82376076 0.788624119,7.32049561 2.04697024,9.21395349 L0.0469702389,13.2186047 C-0.0259284825,13.3710693 -0.013333381,13.5494511 0.0803035723,13.6906977 C0.168386548,13.8330597 0.326673413,13.9196389 0.496970239,13.9186047 L6.16363691,13.9186047 C6.35187855,13.9214555 6.52580287,13.8207861 6.61363691,13.6581395 C6.69584266,13.4962281 6.68312839,13.30374 6.58030357,13.1534884 L5.36363691,11.3790698 C5.63977042,11.417572 5.91808183,11.4393191 6.19697024,11.444186 C7.50508124,11.4451635 8.75928676,10.9352195 9.68030357,10.027907 C9.7684157,9.93513038 9.81621735,9.81256918 9.81363691,9.68604651 C9.81296595,9.56009617 9.76559856,9.43864838 9.68030357,9.34418605 L6.89697024,6.64186047 Z")
			path(d="M8,1.79069767 L8,2.76744186 C9.07964565,2.77637894 9.95003877,3.63379661 9.95,4.68837209 L10.95,4.68837209 C10.9500258,3.09436658 9.63193227,1.79965276 8,1.79069767 Z")
			path(d="M8,0 L8,0.976744186 C10.135501,0.976744186 11.8666667,2.66765015 11.8666667,4.75348837 L12.8666667,4.75348837 C12.8666667,2.12820924 10.6877858,2.89174369e-16 8,0 Z")

		symbol#svg-icon-starts
			path(d="M8.91743119,10.3348624 L8.38990826,10.8394495 C8.2828745,10.9464833 8.15290532,11 8,11 C7.84709468,11 7.7171255,10.9464833 7.61009174,10.8394495 L3.16055046,6.38990826 C3.0535167,6.2828745 3,6.15290532 3,6 C3,5.84709468 3.0535167,5.7171255 3.16055046,5.61009174 L7.61009174,1.16055046 C7.7171255,1.0535167 7.84709468,1 8,1 C8.15290532,1 8.2828745,1.0535167 8.38990826,1.16055046 L8.91743119,1.66513761 C9.02446495,1.77217137 9.0741589,1.90214056 9.06651376,2.05504587 C9.05886862,2.20795119 9.00152917,2.33792037 8.89449541,2.44495413 L6.14220183,5.08256881 L12.7247706,5.08256881 C12.877676,5.08256881 13.0076451,5.13608551 13.1146789,5.24311927 C13.2217127,5.35015302 13.2752294,5.48012221 13.2752294,5.63302752 L13.2752294,6.36697248 C13.2752294,6.51987779 13.2217127,6.64984698 13.1146789,6.75688073 C13.0076451,6.86391449 12.877676,6.91743119 12.7247706,6.91743119 L6.14220183,6.91743119 L8.89449541,9.55504587 C9.00152917,9.66207963 9.05886862,9.79204881 9.06651376,9.94495413 C9.0741589,10.0978594 9.02446495,10.2278286 8.91743119,10.3348624 Z")
			rect(x="0" y="0" width="2" height="12" rx="0.700000048")

		symbol#svg-spacecraft
			path(d="M9.19029073,14.6905877 C12.2271914,14.6905877 14.6901954,12.2275837 14.6901954,9.18912506 C14.6901954,8.95941026 14.5033101,8.77252414 14.2735944,8.77252414 L12.7465798,8.77252414 C11.8627633,8.75227873 12.756703,10.0441295 11.4017773,11.3624554 C9.94718006,12.7812326 8.7721326,11.8872945 8.7721326,12.7088144 L8.7721326,14.2716506 C8.7721326,14.5037008 8.95901871,14.6905877 9.19029073,14.6905877 Z")
			path(d="M8.7721326,17.3794131 C8.7721326,17.6083472 8.95901871,17.7952341 9.19106974,17.7952341 C13.9457654,17.7952341 17.7956216,13.9461577 17.7956216,9.19068229 C17.7956216,8.95941028 17.6102927,8.77252416 17.3790207,8.77252416 L15.8551189,8.77252416 C15.5856923,8.76629531 15.4003634,8.94695175 15.4003634,9.19068229 C15.4003634,11.6926215 13.897486,13.966404 11.5715324,14.9280886 C9.71980149,15.6966575 8.77213258,15.0807119 8.77213258,15.8196913 L8.77213258,17.3794131 Z M9.60689081,16.2238328 C13.170966,16.0151433 16.0147509,13.1690221 16.2257767,9.60728319 L16.9499604,9.60806139 C16.7412709,13.5630406 13.5766656,16.7385488 9.60689081,16.9503528 L9.60689081,16.2238328 Z")
			path(d="M3.61018059,15.8819863 L7.31753541,12.1754101 C7.39384708,12.0990993 7.43433954,11.9978689 7.43979019,11.8997538 L7.57917596,10.1266708 C7.96151406,10.5090089 8.36409842,11.0906917 8.74955096,10.7044601 L10.7056266,8.7499425 C11.0903001,8.36371095 10.5086174,7.96112659 10.1262793,7.5787893 L11.8993615,7.44018174 C11.9998128,7.43395206 12.0987069,7.39423862 12.1750194,7.31714794 L15.8815956,3.61057215 C16.044342,3.44782532 16.044342,3.18229131 15.8815956,3.01876588 L13.188876,0.326825893 C13.0261296,0.164079064 12.7605956,0.164079064 12.597071,0.326825893 L8.89049394,4.0334019 C8.82274817,4.10192709 8.77836227,4.19225529 8.76979719,4.29737867 L8.62885339,6.07980619 C8.24651529,5.69980431 7.84470994,5.11812075 7.45847839,5.50279509 L5.50240354,7.45809174 C5.11772921,7.84510149 5.69941277,8.24690683 6.08175006,8.62924494 L4.29698713,8.7686307 C4.19419997,8.77641759 4.10153552,8.82313974 4.03301036,8.89088548 L0.32643435,12.5974617 C0.163687521,12.760988 0.163687521,13.026522 0.32643435,13.1892684 L3.01915292,15.8819863 C3.18189975,16.0447328 3.44743374,16.0447328 3.61018059,15.8819863 Z M9.59131704,4.51619134 L12.8929727,1.2145353 L14.9923275,3.31466878 L11.69223,6.61554664 L10.2189437,6.73234995 L9.47607094,5.98947716 L9.59131704,4.51619134 Z M7.75360271,6.39206151 L9.81635938,8.45248196 L8.45442662,9.81675094 L6.39166995,7.75399509 L7.75360271,6.39206151 Z M4.5157998,9.5917086 L5.98908561,9.47490529 L6.73195841,10.2177781 L6.61671231,11.6902853 L3.31505584,14.9927199 L1.21570116,12.893365 L4.5157998,9.5917086 Z")
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Authentication from '@/assets/helpers/Authentication';
import TheSidebar from '@/components/TheSidebar.vue';
import TheFeedback from '@/components/TheFeedback.vue';
import TheLoading from '@/components/TheLoading.vue';
import Storage from '@/assets/helpers/LocalStorage';
import axios from 'axios';

const storage = new Storage('auth');

export default {
	name: 'App',
	components: {
		TheSidebar,
		TheLoading,
		TheFeedback
	},
	data() {
		return {
			pushSidebar: false,
			isLoading: true,
			loadStarted: null,
			refreshHandle: null,
			alertRefreshHandle: null,
			updateTimeHandle: null,
			minutesBetweenDataRefresh: 5,
			minutesBetweenAlertRefresh: 2,
			environmentLoaded: false
		};
	},
	computed: {
		noAuthorization() {
			return this.$route.meta.noAuth || this.$route.path === "/";
		},
		requiresAuthorization() {
			return !this.noAuthorization;
		},
		alerts() {
			return this.getActiveAlerts.filter(alert => alert.tags.name !== 'realtime');
		},
		...mapGetters([
			'getActiveAlerts'
		]),
		...mapState([
			'tenant'
		])
	},
	async mounted() {
		axios.interceptors.response.use(
			response => response,
			error => {
				if (error.response.status === 401 && this.requiresAuthorization) {
					this.onLoggedOut();
				}
				return Promise.reject(error);
			}
		);

		// The statement below will always be false, it seems.
		// The mounted method in App.vue is run before the router can give us information
		// about the authorization requirements of the current route
		if (this.requiresAuthorization) {
			const authenticated = await this.onComponentMounted();
			if (authenticated) {
				this.onLoggedIn();
			}
		}

		this.initEnvironment().then(() => {
			this.environmentLoaded = true;
		});

		this.$bus.on('logged_in', this.onLoggedIn);
		this.$bus.on('logged_out', this.onLoggedOut);
		this.$bus.on('component_mounted', this.onComponentMounted);
		this.$bus.on('current_user_updated', this.onCurrentUserUpdated);
		this.$bus.on('exception', this.onException);

		this.subscribe('_error', ({ message }) => this.showNotification(message, 'error'));
	},
	beforeUnmount() {
		this.$bus.off('logged_in', this.onLoggedIn);
		this.$bus.off('logged_out', this.onLoggedOut);
		this.$bus.off('component_mounted', this.onComponentMounted);
		this.$bus.off('current_user_updated', this.onCurrentUserUpdated);
		this.$bus.off('exception', this.onException);

		this.disposeHandlers();
	},
	methods: {
		showSidebar() {
			this.pushSidebar = true;
		},
		hideSidebar() {
			return this.pushSidebar && (this.pushSidebar = false);
		},
		async onLoggedIn() {
			await this.getAlerts();
			this.createHandlers();
		},
		onLoggedOut() {
			this.hideSidebar();
			this.disposeHandlers();

			storage.remove('isAuthenticated');
			storage.remove('userID');
			storage.remove('userType');
			storage.remove('2FAEnabled');

			this.resetStore({ logout: true });

			let path = '/login';

			if (this.$route.name !== 'dashboard') {
				const currentPage = location.pathname + location.search + location.hash;
				path = '/login?redirect_to=' + currentPage;
			}
			this.$router.push({ path: path });
		},
		setTenantId(tenantId) {
			if (tenantId && tenantId !== this.tenant.id) {
				this.isLoading = true;

				new Authentication()
					.setTenant(tenantId)
					.then(response => {
						if (response.status === 'valid') {
							this.tenantChanged(response.tenant_id);
						} else {
							this.isLoading = false;
						}
					});
			}
		},
		tenantChanged(newTenantId) {
			return this.ensureTenants().then(() => {
				return this.setTenant(newTenantId)
					.then(() => {
						this.$bus.emit('refreshed_data');
						this.isLoading = false;
					});
			});
		},
		async onComponentMounted() {
			if (this.requiresAuthorization) {
				return await this.validateAuthenticationState();
			}
		},
		onCurrentUserUpdated() {
			this.refreshTenants().then(this.validateAuthenticationState);
		},
		onException({ ex, message, type }) {
			if (ex?.status !== 401 && message) {
				this.showNotification(message, type);
			}
		},
		createHandlers() {
			this.ensureSockets();

			if (!this.refreshHandle) {
				this.refreshHandle = setInterval(() => {
					this.refreshData()
						.then(() => this.$bus.emit('refreshed_data'));
				}, this.minutesBetweenDataRefresh * 60 * 1000);
			}

			if (!this.alertRefreshHandle) {
				this.alertRefreshHandle = setInterval(() => {
					this.getAlerts()
						.then(() => this.$bus.emit('refreshed_alerts'));
				}, this.minutesBetweenAlertRefresh * 60 * 1000);
			}

			if (!this.updateTimeHandle) {
				this.updateTimeHandle = this.startTime();
			}
		},
		disposeHandlers() {
			this.closeSockets();

			if (this.refreshHandle) {
				clearInterval(this.refreshHandle);
			}
			if (this.alertRefreshHandle) {
				clearInterval(this.alertRefreshHandle);
			}
			if (this.updateTimeHandle) {
				clearInterval(this.updateTimeHandle);
			}

			this.refreshHandle = null;
			this.alertRefreshHandle = null;
			this.updateTimeHandle = null;
		},
		validateAuthenticationState() {
			return new Authentication()
				.state()
				.then(response => {
					if (response.status === 'valid') {
						storage.save('isAuthenticated', true);
						storage.save('userType', response.type);
						this.onLoggedIn();

						if (this.tenant?.id === response.tenant_id) {
							return true;
						}

						return this.tenantChanged(response.tenant_id).then(() => {
							return true;
						});
					}
					this.onLoggedOut();
					return false;
				});
		},
		...mapActions([
			'getAlerts',
			'refreshData',
			'startTime',
			'resetStore',
			'setTenant',
			'ensureTenants',
			'refreshTenants'
		])
	}
};
</script>

<style lang="scss">
@import '@/assets/scss/fonts';
@import '@fortawesome/fontawesome-pro/css/all.css';

@import '@/assets/scss/normalize';
@import '@/assets/scss/themes';
@import '@/assets/scss/grid';
@import '@/assets/scss/typography';
@import '@/assets/scss/datetimepicker';
@import '@/assets/scss/forms';
@import '@/assets/scss/buttons';
@import '@/assets/scss/collapsible';
@import '@/assets/scss/callouts';
@import '@/assets/scss/custom';

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
}

body {
	margin: 0;
	line-height: 1.5;
	font-weight: 400;
}

html,
body,
#app,
#container,
#content-wrap {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--body-font-family);
	box-sizing: border-box;
	height: 100%;
	color: var(--bodyColor);
	font-size: 100%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

#container {
	position: relative;
	overflow: hidden;
	transition: padding-left .2s;

	@include breakpoint(medium) {
		padding-left: $sidebar-width;
	}

	&.no-auth {
		padding-left: 0;

		#content-wrap {
			padding: 0;

			#content {
				padding: 0;
			}
		}
	}
}

#content-header {
	position: fixed;
	top: 0; right: 0; left: 0;
	background: var(--bodyBackground);
	padding: rem-calc(20);

	h1 {
		text-align: center;
		font-weight: $global-weight-bold;
		text-transform: uppercase;
		font-size: rem-calc(16);
		line-height: 1;
		margin: 0;
	}

	@include breakpoint(medium) {
		display: none;
	}
}

#toggle-sidebar {
	position: absolute;
	top: rem-calc(20);
	left: rem-calc(20);
	font-size: rem-calc(16);
	outline: none;
	color: var(--bodyColor);
}

#content-wrap {
	display: flex;
	flex-flow: column;
	position: relative;
	background: var(--bodyBackground);
	transition: transform $sidebar-duration ease;
	will-change: transform;
	padding: rem-calc(56) 0 0;
	z-index: 2;
	left: 0;

	&:after {
		position: fixed;
		top: 0; right: 0;
		content: '';
		background-color: hsla(var(--black), 0.15);
		overflow: hidden;
		opacity: 0;
		transition: opacity $sidebar-duration;
		will-change: opacity;
		z-index: 1000;
	}

	.push & {
		transform: translate3d($sidebar-width, 0, 0);

		&:after {
			width: 100%;
			height: 100%;
			opacity: 1;
		}

		@include breakpoint(medium) {
			transform: none;

			&:after {
				opacity: 0;
			}
		}
	}

	@include breakpoint(medium) {
		padding: 0;
	}
}

#content {
	flex-grow: 1;
	display: flex;
	flex-flow: column;
	width: 100%;
	padding: 0 $global-padding $global-padding;
	overflow-y: auto;
	@include scrollbars(.5rem, var(--inputBorderColor), var(--boxBackground));

	> div:not(#login):not(.system-alert) {
		padding-top: $global-padding;
	}
}

.tooltip {
	display: block !important;
	z-index: 10000;
	padding: 0;
	max-width: 10rem;
	background-color: hsl(var(--black));
	font-size: 80%;
	color: $white;

	.tooltip-inner {
		padding: rem-calc(10);
		font-weight: $global-weight-bold;
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: hsl(var(--black));
		z-index: 1;
	}

	&[x-placement^="top"] {
		margin-bottom: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 0 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			bottom: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="bottom"] {
		margin-top: 5px;

		.tooltip-arrow {
			border-width: 0 5px 5px 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			top: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="right"] {
		margin-left: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 5px 0;
			border-left-color: transparent !important;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			left: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[x-placement^="left"] {
		margin-right: 5px;

		.tooltip-arrow {
			border-width: 5px 0 5px 5px;
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			right: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&.popover {
		$color: #f9f9f9;

		.popover-inner {
			background: $color;
			color: black;
			padding: 24px;
			border-radius: 5px;
			box-shadow: 0 5px 30px rgba(black, .1);
		}

		.popover-arrow {
			border-color: $color;
		}
	}

	&[aria-hidden='true'] {
		visibility: hidden;
		opacity: 0;
		transition: opacity .15s, visibility .15s;
	}

	&[aria-hidden='false'] {
		visibility: visible;
		opacity: 1;
		transition: opacity .15s;
	}
}

.map-container {
	padding: rem-calc(10);
	flex-shrink: 0;
	align-self: flex-end;
	text-align: right;
	display: none;
	margin-left: auto;

	@include breakpoint(medium) {
		display: block;
	}

	.map {
		position: relative;
		float: right;
	}

	.map-marker {
		position: absolute;
		transform: translate(-50%, -100%);
		opacity: 1;
		visibility: hidden;
		transition: all .2s;
	}
}

@mixin map-marker($name, $top: 0, $right: 0) {
	header.station-#{$name} .map-marker {
		top: rem-calc($top);
		left: rem-calc($right);
		visibility: visible;
	}
}

.checkbox {
	display: inline-flex;
	align-items: center;
	margin: rem-calc(0 0 10 0);
	padding: rem-calc(5 10 5 5);
	cursor: pointer;
	position: relative;

	td & {
		margin-bottom: 0;
	}

	label {
		color: var(--bodyColor);
		font-weight: $global-weight-bold;
		margin: rem-calc(0 0 0 4);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-shadow: 0 1px 2px hsla(var(--black), .25);
	}

	&.boolean {

		input {
			margin: rem-calc(8 14);
		}

		label {
			margin: 0;
			transition: opacity .2s linear;

			&:not(:first-child) {
				opacity: .5;
			}
		}

		&.checked label {
			opacity: 1;

			&:not(:last-child) {
				opacity: .5;
			}
		}
	}

	input[type="checkbox"] {
		appearance: none;
		cursor: pointer;
		position: relative;
		width: rem-calc(20);
		height: rem-calc(6);
		border-radius: rem-calc(3);
		margin: rem-calc(8 10);
		padding: 0;
		background-color: hsl(var(--hue), var(--saturation), 50%);
		outline: none;

		&:before {
			content: '\f00c';
			font-family: $font-awesome-family;
			display: inline-flex;
			position: absolute;
			top: rem-calc(-6);
			left: rem-calc(-6);
			width: rem-calc(17);
			height: rem-calc(17);
			transition: left cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
			align-items: center;
			justify-content: center;
			font-size: rem-calc(10);
			font-weight: 600;
			border-radius: 50%;
			background-color: $white;
			color: $white;
			box-shadow: 0 1px 2px hsla(var(--black), .5);
		}

		&:checked {
			background-color: var(--primaryColor);

			&:before {
				left: rem-calc(6);
				background-color: var(--primaryColor);
			}
		}
	}
}

.system-alert {
	background-color: var(--warn-color);
	color: hsl(var(--black));
	overflow: hidden;
	position: relative;
	flex-shrink: 0;
	margin: 0 neg($global-padding) 0;

	&:before {
		content: fa-content($fa-var-exclamation);
		font-family: $font-awesome-family;
		position: absolute;
		bottom: rem-calc(-10);
		left: rem-calc(5);
		font-size: rem-calc(72);
		line-height: 1;
		display: inline-block;
		color: hsla(0, 0%, 100%, 0.2);
		font-weight: 700;
	}

	&:not(:first-child) {
		box-shadow: inset 0 1px 0 0 hsla(0, 0%, 0%, 0.1);
	}

	&.low {
		background-color: var(--success-color);
	}

	&.high {
		background-color: var(--error-color);
	}

	.content {
		display: flex;
		position: relative;
		align-items: center;
		padding: rem-calc(10 20);
	}

	h4 {
		margin: 0;
		font-size: rem-calc(16);
		font-weight: $global-weight-bold;
		margin-right: rem-calc(15);
	}

	p {
		margin: 0;
		font-size: rem-calc(16);
	}
}

#container .ksat-notification {
	background: var(--boxBackground);
	margin: rem-calc(0 10 10);
	padding: rem-calc(10);
	box-shadow: 0 1px 10px hsla(0, 0%, 0%, 0.1);
	border-radius: rem-calc(3);
	border-bottom: 3px solid var(--boxBorderColor);
	color: var(--bodyColor);

	.notification-content {
		display: flex;
		align-items: center;

		&:before {
			content: '\f069';
			font-family: $font-awesome-family;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: rem-calc(24);
			font-weight: 900;
			color: var(--boxBorderColor);
			margin-right: rem-calc(10);
			width: rem-calc(30);
		}
	}

	&.warn {
		border-color: var(--warn-color);
		.notification-content:before { color: var(--warn-color); content: '\f12a';}
	}

	&.error {
		border-color: var(--error-color);
		.notification-content:before { color: var(--error-color); content: '\f00d'; }
	}

	&.success {
		border-color: var(--success-color);
		.notification-content:before { color: var(--success-color); content: '\f00c'; }
	}
}

.vgt-wrap {

	table.vgt-table {
		background: transparent;
		color: var(--bodyColor);
		border: 0;

		thead {

			th {
				background: transparent;
				color: inherit;
				border: 0;
				padding: rem-calc(8 10 10);
			}
		}

		tbody {

			td {
				background: transparent;
				color: inherit;
				border: 0;
				padding: rem-calc(8 10 10);
				vertical-align: middle;
			}
		}
	}
}

.align-right {
	justify-content: flex-end;
}

.no-margin {
	margin-bottom: 0;
}

.environment-border {

	&.development,
	&.dev {
		border: 4px solid hsl(var(--dev-color));
	}

	&.test {
		border: 4px solid hsl(var(--test-color));
	}

	&.staging,
	&.preprod {
		border: 4px solid hsl(var(--staging-color));
	}
}

// system minimap map-markers
@include map-marker('ALASKA_AWS', 15, 7);
@include map-marker('ATHENS', 27, 46);
@include map-marker('AWARUA', 50, 81);
@include map-marker('BAHRAIN_AWS', 32, 53);
@include map-marker('BROSTADBOTN', 14, 46);
@include map-marker('BYALAU', 35, 59);
@include map-marker('CAPE_TOWN_AWS', 46, 46);
@include map-marker('DUBAI', 32, 53);
@include map-marker('DUBBO_AWS', 46, 75);
@include map-marker('FAIRBANKS', 17, 5);
@include map-marker('Fairbanks_Iridium', 17, 5);
@include map-marker('FAIRBANKS_MAXAR', 17, 5);
@include map-marker('HAPPY_VALLEY_GOOSE_BAY', 23, 27);
@include map-marker('HARTEBEESTHOEK', 45, 47);
@include map-marker('HAWAII', 33, 3);
@include map-marker('HAWAII_AWS', 33, 3);
@include map-marker('INUVIK', 15, 9);
@include map-marker('INUVIK_C_CORE', 15, 9);
@include map-marker('IRELAND_AWS', 22, 39);
@include map-marker('JEJU_ISLAND', 29, 71);
@include map-marker('KJELLER', 20, 43);
@include map-marker('KOUROU', 38, 27);
@include map-marker('LONG_BEACH', 29, 13);
@include map-marker('MASPALOMAS', 31, 36);
@include map-marker('MAURITIUS', 43, 55);
@include map-marker('MIAMI', 31, 21);
@include map-marker('MINGENEW', 45, 68);
@include map-marker('OHIO_AWS', 29, 21);
@include map-marker('OREGON_AWS', 27, 12);
@include map-marker('PAUMALU', 33, 3);
@include map-marker('PETERBOROUGH', 46, 73);
@include map-marker('PUERTOLLANO', 28, 40);
@include map-marker('PUNTA_ARENAS', 53, 24);
@include map-marker('PUNTA_ARENAS_AWS', 53, 24);
@include map-marker('SEOUL_AWS', 29, 71);
@include map-marker('SINGAPORE', 38, 66);
@include map-marker('SINGAPORE_AWS', 38, 66);
@include map-marker('STOCKHOLM_AWS', 20, 45);
@include map-marker('SVALBARD', 4, 45);
@include map-marker('SVALSAT', 4, 45);
@include map-marker('THERMOPYLAE', 27, 46);
@include map-marker('THOMASTON', 30, 21);
@include map-marker('TOLHUIN', 53, 24);
@include map-marker('TORREJON', 28, 40);
@include map-marker('TROLL', 62, 43);
@include map-marker('TROMSO', 14, 46);
@include map-marker('TROMSOE', 14, 46);
@include map-marker('VARDO', 14, 48);
@include map-marker('VESTERALEN', 14, 46);
@include map-marker('WEILHEIM_DLR', 25, 43);
</style>
