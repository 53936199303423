<template lang="pug">
aside#sidebar
	header
		.logo
			a.logo-bg(src="/")
				img.logo-type(src="@/assets/images/ksat-logo.svg")
			.text
				p {{ this.now.toFormat('MMM dd - HH:mm:ss') }}

		.tenant-picker(v-if="pickedTenant && multipleTenants")
			label Tenant
				VDropdown(
					v-model="pickedTenant"
					:options="tenants"
					placeholder="Select tenant"
				)
	nav#main-nav
		ul
			li(
				v-for="item in menuItems"
				:key="item.title"
				@click="linkClicked(item)"
				:class="[item.name, {'has-submenu': item.submenu}, {'show-submenu': item.showSubmenu}]"
			)
				RouterLink.link(
					:to="item.url"
					:class="{ 'is-active': checkIsActive(item) || subMenuActive(item) }"
				)
					fa-icon(:icon="item.icon")
					span {{item.title}}
					strong.new-changes(v-if="item.counter") {{ item.counter }}
				ul.submenu(v-if="item.submenu")
					li(
						v-for="submenuItem in item.submenu"
						:key="submenuItem.url"
						:class="submenuItem.name"
						@click.stop="hideSidebar"
					)
						router-link.link(
							v-if="submenuItem.name !== 'seperator'"
							:to="submenuItem.url"
							:class="{ 'is-active': checkIsActive(submenuItem) }"
						)
							span {{ submenuItem.title }}
			li
				a.link(@click="logout")
					fa-icon(icon="power-off")
					span Log out
	EnvironmentIndicator
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EnvironmentIndicator from '@/components/EnvironmentIndicator.vue';
import Authentication from '@/assets/helpers/Authentication';

export default {
	name: 'TheSidebar',
	components: {
		EnvironmentIndicator
	},
	emits: ['tenant-changed', 'hide-sidebar'],
	data() {
		return {
			pickedTenant: null,
			menuItems: [
				{ title: 'Dashboard', name: 'dashboard', icon: 'chart-bar', url: '/' },
				{ title: 'Operational', name: 'operational', icon: 'wrench', url: '/operational' },
				{ title: 'Timeline', name: 'timeline', icon: 'calendar', url: '/timeline' },
				{ title: 'Contacts', name: 'contacts', icon: 'list', url: '/contacts' },
				...this.isFeatureEnabled('new_contact') ? [{ title: 'New contact', name: 'new_contact', icon: 'plus', url: '/newcontact' }] : [],
				...this.isFeatureEnabled('realtime') ? [{ title: 'Real-time', name: 'realtime', icon: 'tachometer-alt', url: '/realtime' }] : [],
				{ title: 'Systems', name: 'systems', icon: 'map-marked-alt', url: '/systems' },
				{ title: 'Spacecrafts', name: 'spacecrafts', icon: 'satellite', url: '/spacecrafts' },
				{ title: 'Documentation', name: 'documentation', icon: 'file-alt', url: '/documentation' },
				{ title: 'Settings', name: 'settings', icon: 'cog', url: '/settings' },
				{ title: 'Release log', name: 'release_log', icon: 'list-alt', url: '/releaselog', counter: 0 }
			]
		};
	},
	computed: {
		multipleTenants() {
			return this.tenants?.length > 1;
		},
		...mapState([
			'newestReleaselog',
			'seenReleaselog',
			'tenant',
			'tenants',
			'now'
		])
	},
	watch: {
		pickedTenant() {
			if (this.pickedTenant) {
				this.$emit('tenant-changed', this.pickedTenant.id);
			}
		},
		tenants() {
			if (this.tenant && this.tenants?.length) {
				this.pickedTenant = this.tenants.find(tenant => tenant.id === this.tenant.id);
			}
		},
		newestReleaselog() {
			const releaselog = this.menuItems.find(item => item.name === 'release_log');
			releaselog.counter = this.newestReleaselog.length;
		},
		seenReleaselog() {
			const releaselog = this.menuItems.find(item => item.name === 'release_log');
			releaselog.counter = 0;
		}
	},
	mounted() {
		if (this.tenant && this.tenants?.length) {
			this.pickedTenant = this.tenants.find(tenant => tenant.id === this.tenant.id);
		}

		if (this.hasCustomerAdminAccess()) {
			const adminPage = { title: 'Admin', icon: 'user', url: '/admin' };
			this.menuItems.push(adminPage);
		}

		// Remove internal page for Portal API unless user is an admin
		if (this.isAdmin()) {
			this.menuItems = this.menuItems.filter(item => item.name !== 'portal-api');
		}

		this.menuItems
			.filter(item => item.submenu)
			.forEach(item => {
				item.showSubmenu = this.subMenuActive(item);
			});

		this.getNewestReleaselog();
	},
	methods: {
		subMenuActive(item) {
			// Avoid checks for Dashboard since URL matches everything
			if (item.url === '/') {
				return;
			}

			const href = window.location.href;
			const submenu = item.submenu || [];
			const hasPage = !href.includes('#');
			const isRelated = hasPage && href.includes(item.url);

			return isRelated || submenu
				.map(item => item.url)
				.filter(url => href.includes(url))
				.length;
		},
		linkClicked(item) {
			if (item.submenu) {
				item.showSubmenu = !item.showSubmenu;

				// Ensures all other menu-items with submenus are closed
				this.menuItems
					.filter(found => this.hasSubMenu(found) && found.name !== item.name)
					.forEach(found => {
						found.showSubmenu = false;
					});
				return;
			}
			this.hideSidebar();

			// Item without submenu was clicked, so all items with submenus should close
			this.menuItems
				.filter(this.hasSubMenu)
				.forEach(found => {
					found.showSubmenu = false;
				});
		},
		hasSubMenu(item) {
			return !!item.submenu;
		},
		hideSidebar() {
			this.$emit('hide-sidebar');
		},
		logout() {
			new Authentication().logout()
				.then(() => {
					this.$bus.emit('logged_out');
				});
		},
		checkIsActive(item) {
			if (item.url === '/') {
				return window.location.href.includes('dashboard');
			}

			return window.location.href.includes(item.url);
		},
		...mapActions([
			'getNewestReleaselog',
			'ensureAssets'
		])
	}
};
</script>

<style lang="scss" scoped>
	#sidebar {
		display: flex;
		flex-flow: column;
		position: absolute;
		top: 0;
		left: 0;
		width: $sidebar-width;
		height: 100%;
		background: var(--sidebarBackground);
		color: $white;
		z-index: 1;
		box-shadow: inset -11px 0 8px -10px hsla(var(--black), 0.25);
		transition: all .2s;

		header {
			padding-bottom: 10px;
		}

		.logo {
			opacity: 1;
			visibility: visible;
			transition: all .2s;
			height: rem-calc(90);
			position: relative;
			padding: var(--logoPadding);
			margin-left: var(--logoMargin);
			flex-shrink: 0;
			width: $sidebar-width;

			> a {
				display: block;
				position: relative;
			}

			.logo-bg {
				background-color: (var(--navActiveBg));
				clip-path: polygon(0% 0%, 100% 0%, 91.5% 100%, 0% 100%);
				width: rem-calc(142);
				height: rem-calc(67);
			}

			.logo-type {
				position: absolute;
				top: rem-calc(8);
				left: rem-calc(10);
				width: rem-calc(110);
				transform: scale(0.8);
				transition: all .2s;
			}

			.text {
				margin-left: rem-calc(20);
				margin-top: rem-calc(-26);
				font-size: rem-calc(14);
				font-weight: 600;
				text-shadow: 0 1px 2px hsla(var(--black), 0.5);
			}
		}

		.tenant-picker {
			margin-top: 1rem;
			padding: rem-calc(0 10);
		}
	}

	#main-nav {
		flex-grow: 1;
		min-width: 0;
		overflow: hidden scroll;
		transition: all 0.2s;
		width: 100%;
		@include scrollbars(.35rem, var(--inputBorderColor), transparent);

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {

			&.has-submenu {

				&.show-submenu {

					> a {
						background-color: var(--navActiveBg);
						box-shadow: inset 3px 0 0 $white;
						position: relative;
					}

					.submenu {
						max-height: rem-calc(600);
					}
				}
			}

			@include breakpoint(medium) {

				a.router-link-exact-active:after {
					@include triangle(var(--bodyBackground), left, 5px, 5px);
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}
			}
		}

		a,
		.link {
			color: inherit;
			text-transform: uppercase;
			padding: rem-calc(10 10 10 20);
			display: flex;
			align-items: center;
			justify-content: flex-start;
			line-height: 1;
			font-size: rem-calc(14);
			font-weight: $global-weight-bold;
			transition: all 0.1s;
			text-shadow: 0 1px 2px hsla(var(--black), 0.5);
			position: relative;
			white-space: nowrap;
			user-select: none;
			cursor: pointer;

			> span {
				opacity: 1;
				visibility: visible;
				transition: all .2s;
			}

			&.router-link-exact-active::after {
				content: none;
			}

			&.is-active {
				background-color: var(--navActiveBg);
				box-shadow: inset 3px 0 0 $white;
				position: relative;
			}

			svg {
				margin-right: rem-calc(10);
				width: 14px;
				text-align: center;
				transition: margin 0.2s;
			}

			.updates {
				position: absolute;
				top: 12px;
				left: 28px;
				display: block;
				border-radius: 50%;
				width: 15px;
				height: 15px;
				line-height: 15px;
				text-align: center;
				background-color: var(--primaryColor);
				color: $white;
				font-size: rem-calc(10);
				font-weight: $global-weight-bold;
				text-shadow: none;
				box-shadow: 0 1px 1px hsla(var(--black), 0.25);
			}

			&:hover {
				background-color: var(--navActiveBg);
			}
		}

		.submenu {
			overflow: hidden;
			max-height: 0;
			transition: all .3s ease-in-out;
			background-color: var(--navSubmenuBg);

			li {

				a {

					&:before {
						content: '';
						display: inline-block;
						width: rem-calc(14);
						height: rem-calc(14);
						line-height: rem-calc(14);
						font-size: rem-calc(12);
						border-radius: 50%;
						text-align: center;
						overflow: hidden;
						max-width: 0;
						transition: all 0.1s;
						opacity: 0;
						background: $white;
						color: var(--secondaryColor);
					}
				}
			}
		}

		.seperator {
			background: hsla(198, 30%, 60%, 0.4);
			height: rem-calc(2);
		}

		.new-changes {
			position: absolute;
			top: rem-calc(4);
			left: rem-calc(10);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			border-radius: 50%;
			width: rem-calc(14);
			height: rem-calc(14);
			background: var(--error-color);
			color: var(--bodyColor);
			text-shadow: none;
			font-size: rem-calc(10);
			line-height: 1;
			text-shadow: 0 1px 2px hsla(var(--black), 0.5);
			box-shadow: 0 1px 3px hsla(0, 0%, 0%, .25);
		}
	}
</style>
