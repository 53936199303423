import { createRouter, createWebHistory } from 'vue-router';

const Dashboard = () => import('./views/Dashboard.vue');
const Operational = () => import('./views/Operational.vue');
const Timeline = () => import('./views/Timeline.vue');
const Contacts = () => import('./views/Contacts.vue');
const Realtime = () => import('./views/Realtime.vue');
const RealtimeView = () => import('./views/RealtimeView.vue');
const Postpass = () => import('./views/Postpass.vue');
const System = () => import('./views/System.vue');
const SystemView = () => import('./views/SystemView.vue');
const Spacecraft = () => import('./views/Spacecraft.vue');
const Admin = () => import('./views/Admin.vue');
const Settings = () => import('./views/Settings.vue');
const Documentation = () => import('./views/Documentation.vue');
const Releaselog = () => import('./views/Releaselog.vue');
const NewContact = () => import('./views/NewContact.vue');
const EditContact = () => import('./views/EditContact.vue');
const Login = () => import('./views/Login.vue');
const ResetPassword = () => import('./views/ResetPassword.vue');
const NotFound = () => import('./views/NotFound.vue');

const redirectTo = url => ({
	template: '',
	created: function() {
		window.location.href = url;
	}
});

const routes = [
	{
		path: '/',
		redirect: {
			name: 'dashboard'
		}
	},
	{
		path: '/dashboard',
		alias: '/dashboard',
		name: 'dashboard',
		meta: {
			title: 'Dashboard'
		},
		component: Dashboard
	},
	{
		path: '/operational',
		name: 'operational',
		meta: {
			title: 'Operational'
		},
		component: Operational
	},
	{
		path: '/timeline',
		name: 'timeline',
		meta: {
			title: 'Timeline'
		},
		component: Timeline
	},
	{
		path: '/contacts',
		name: 'contacts',
		meta: {
			title: 'Contacts'
		},
		component: Contacts
	},
	{
		path: '/realtime',
		name: 'realtime',
		meta: {
			title: 'Real-time'
		},
		component: Realtime
	},
	{
		path: '/realtime/:name',
		name: 'realtime_view',
		props: true,
		meta: {
			title: 'Real-time detailed'
		},
		component: RealtimeView
	},
	{
		path: '/postpass/:id',
		name: 'postpass',
		props: true,
		meta: {
			title: 'Postpass report',
			requiresFeature: 'realtime'
		},
		component: Postpass
	},
	{
		path: '/systems',
		name: 'systems',
		meta: {
			title: 'Systems'
		},
		component: System
	},
	{
		path: '/systems/:name',
		name: 'system_view',
		props: true,
		meta: {
			title: 'System detailed'
		},
		component: SystemView
	},
	{
		path: '/newcontact',
		name: 'new_contact',
		meta: {
			title: 'New contact',
			requiresFeature: 'new_contact'
		},
		component: NewContact
	},
	{
		path: '/editcontact/:id',
		name: 'edit_contact',
		props: true,
		meta: {
			title: 'Edit contact',
			requiresFeature: 'new_contact'
		},
		component: EditContact
	},
	{
		path: '/spacecrafts',
		name: 'spacecrafts',
		meta: {
			title: 'Spacecrafts'
		},
		component: Spacecraft
	},
	{
		path: '/admin',
		name: 'admin',
		meta: {
			title: 'Admin'
		},
		component: Admin
	},
	{
		path: '/settings',
		name: 'settings',
		meta: {
			title: 'Settings'
		},
		component: Settings
	},
	{
		path: '/documentation',
		name: 'documentation',
		meta: {
			title: 'Documentation'
		},
		component: Documentation
	},
	{
		path: '/releaselog',
		name: 'release_log',
		meta: {
			title: 'Release log'
		},
		component: Releaselog
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: 'Login',
			noAuth: true
		},
		component: Login
	},
	{
		path: '/password/reset',
		name: 'password_reset',
		meta: {
			title: 'Password reset',
			noAuth: true
		},
		component: ResetPassword
	},
	{
		path: '/404',
		name: 'not_found',
		meta: {
			title: 'Not found',
			noAuth: true
		},
		component: NotFound
	},
	{
		path: '/:path(.*)*',
		component: redirectTo('/404')
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
