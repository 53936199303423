<template lang="pug">
.dropdown-container(:class="modifierClasses")
	label(v-if="header") {{ header }}
	.dropdown(
		:class="{ focus: open, 'disabled': _disabled }"
	)
		.dropdown-label(
			v-click-away="closeDropdown"
			@click="_disabled ? null : toggleDropdown()"
		)
			fa-icon.icon(
				v-if="icon"
				:icon="icon"
			)
			.name(:title="currentLabel")
				| {{ currentLabel }}
			fa-icon.icon.disabled-icon(
				v-if="_disabled"
				icon="lock"
			)
			fa-icon.icon.arrow(
				v-else
				:icon="'caret-down'"
			)
		.content(v-if="open")
			ul.list(
				v-if="options"
				:class="{'border-between-options': borderBetweenOptions}"
			)
				template(
					v-for="option in optionsSorted"
					:key="option[keyProp]"
				)
					DropdownItem(
						:labelProp="labelProp"
						:item="option"
						:selected="optionSelected(option)"
						:multiSelect="multiSelect"
						:displayCheck="displayChecks"
						:truncateName="truncateNames"
						@change="toggleCheck"
					)
						slot(:option="option")
</template>

<script>
import Lodash from '@/assets/helpers/Lodash';
import DropdownItem from '@/components/DropdownItem.vue';

export default {
	name: 'VDropdown',
	components: {
		DropdownItem
	},
	props: {
		modelValue: {
			type: [Object, String, Number],
			required: false,
			default: null
		},
		preserveOrder: {
			type: Boolean,
			required: false,
			default: false
		},
		placeholder: {
			type: String,
			required: false,
			default: null
		},
		stringValue: {
			type: Boolean,
			required: false,
			default: false
		},
		keyProp: {
			type: String,
			required: false,
			default: 'id'
		},
		labelProp: {
			type: String,
			required: false,
			default: 'name'
		},
		icon: {
			type: String,
			required: false,
			default: ''
		},
		options: {
			type: Array,
			required: false,
			default: null
		},
		displayChecks: {
			type: Boolean,
			required: false,
			default: false
		},
		multiSelect: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		header: {
			type: String,
			required: false,
			default: ''
		},
		truncateNames: {
			type: Boolean,
			required: false,
			default: false
		},
		borderBetweenOptions: {
			type: Boolean,
			required: false,
			default: false
		},
		errors: {
			type: Boolean,
			required: false,
			default: false
		},
		changes: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			open: false
		};
	},
	computed: {
		_disabled() {
			return this.disabled || this.options.length < 2;
		},
		currentValue() {
			if (this.modelValue === null) {
				return null;
			}

			return this.stringValue ? this.modelValue : this.modelValue[this.keyProp];
		},
		currentLabel() {
			if (this.modelValue === null || (this.multiSelect && !this.modelValue.length)) {
				return this.placeholder;
			}

			if (this.multiSelect) {
				return this.modelValue.map(entry => this.optionLabel(entry)).join(', ');
			}
			return this.optionLabel(this.modelValue);
		},
		optionsSorted() {
			if (this.preserveOrder) {
				return this.options;
			}

			return new Lodash([...this.options])
				.sortByAlphaNum(this.labelProp)
				.value();
		},
		modifierClasses() {
			return {
				'has-error': this.errors,
				'has-change': this.changes
			};
		}
	},
	watch: {
		options: {
			handler(newValue) {
				if (newValue.length === 1 && !this.multiSelect) {
					this.$emit('update:modelValue', this.outgoingValue(this.options[0]));
				}
			},
			immediate: true
		}
	},
	methods: {
		closeDropdown() {
			this.open = false;
		},
		toggleDropdown() {
			this.open = !this.open;
		},
		optionSelected(option) {
			if (!this.modelValue) {
				return false;
			}

			const optionValue = this.outgoingValue(option);
			if (this.multiSelect) {
				if (this.stringValue) {
					return this.modelValue.includes(optionValue);
				}
				return this.modelValue.some(val => val[this.keyProp] === optionValue[this.keyProp]);
			}

			if (this.stringValue) {
				return this.modelValue === optionValue;
			}
			return this.modelValue[this.keyProp] === optionValue[this.keyProp];
		},
		optionLabel(option) {
			return this.stringValue ? option : option[this.labelProp];
		},
		outgoingValue(selectedOption) {
			if (this.stringValue) {
				return selectedOption[this.keyProp];
			}

			return selectedOption;
		},
		toggleCheck(option) {
			const optionValue = this.outgoingValue(option);
			if (this.multiSelect) {
				if (this.modelValue === null) {
					this.$emit('update:modelValue', [optionValue]);
				} else if (this.optionSelected(option)) {
					this.deselectOption(option);
				} else {
					this.$emit('update:modelValue', [...this.modelValue, optionValue]);
				}
			} else {
				this.$emit('update:modelValue', optionValue);
			}

			if (!this.multiSelect) {
				this.toggleDropdown();
			}
		},
		deselectOption(option) {
			const optionValue = this.outgoingValue(option);
			if (this.stringValue) {
				this.$emit('update:modelValue', this.modelValue.filter(val => val !== optionValue));
			} else {
				this.$emit('update:modelValue', this.modelValue.filter(val => val[this.keyProp] !== optionValue[this.keyProp]));
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.dropdown-container {
	&.has-change {
		.dropdown .dropdown-label {
			border: solid 1px hsl(var(--orange));
		}
	}

	&.has-error {
		.dropdown .dropdown-label {
			border: solid 1px var(--error-color) !important;
		}
	}
}
</style>
