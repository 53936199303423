<template lang="pug">
span.indicator(v-if="!isProduction" :class="environment") {{ environment.toUpperCase() }}
</template>

<script>

export default {
	name: 'EnvironmentIndicator',
	computed: {
		environment() {
			return this.getEnvironment();
		},
		isProduction() {
			return this.environment === 'production' || this.environment === 'prod';
		}
	}
};
</script>

<style lang="scss" scoped>
.indicator {
	justify-content: center;
	align-items: center;
	position: relative;
	border-radius: rem-calc(3);
	color: hsl(var(--black));
	left: 25%;
	width: 50%;
	margin-bottom: 1em;
	padding: 1em;
	font-weight: $global-weight-bold;
	line-height: 1.1;
	height: rem-calc(16);
	display: inline-flex;

	&.development,
	&.dev {
		background-color: hsl(var(--dev-color));
	}

	&.test {
		background-color: hsl(var(--test-color));
	}

	&.staging,
	&.preprod {
		background-color: hsl(var(--staging-color));
	}
}
</style>
