<template lang="pug">
#feedback(:class="{open: isActive}" @click="isActive = true")
	header
		fa-icon(icon="comment-dots")
		span.title Got feedback?
		span.close-chat(@click.stop="isActive = false")
			fa-icon(icon="times")
	.intro We appreciate all feedback and suggestions
	.feedback
		textarea(placeholder="Please leave your feedback here" v-model="feedback")
	footer
		.checkbox
			input#current-page(type="checkbox" v-model="appliesToCurrentPage")
			label(for="current-page") Applies to current page
		button(@click="submitFeedback" :disabled="isSending")
			fa-icon(icon="paper-plane")
			| Submit feedback
</template>

<script>
import Issue from '@/assets/helpers/Issue.js';

export default {
	name: 'TheFeedback',
	data() {
		return {
			isActive: false,
			isSending: false,
			appliesToCurrentPage: false,
			feedback: ''
		};
	},
	computed: {
		page() {
			return this.appliesToCurrentPage ? location.pathname : null;
		}
	},
	methods: {
		submitFeedback() {
			this.isSending = true;

			new Issue()
				.sendFeedback(this.feedback, this.page)
				.then(() => {
					this.isActive = false;
					this.isSending = false;
					this.feedback = '';
					this.showNotification('Your feedback has been sent. Thank you!', 'success');
				})
				.catch(error => {
					console.log(error);
					this.isSending = false;
					this.showNotification('An error occured with sending your feedback');
				});
		}
	}
};
</script>

<style lang="scss" scoped>
#feedback {
	display: flex;
	flex-flow: column;
	position: fixed;
	bottom: 0;
	right: $global-margin;
	z-index: 10;
	width: 250px;
	height: 46px;
	border-radius: rem-calc(3 3 0 0);
	overflow: hidden;
	box-shadow: 0 0 15px hsla(var(--black), 0.25);
	transition: height 0.2s ease, width 0.2s ease 0.2s;
	background: var(--boxBackground);

	@include breakpoint(small-only) {
		width: 46px;

		> header > span {
			visibility: hidden;
			opacity: 0;
		}
	}

	.intro,
	.feedback,
	footer {
		transition: opacity 0.2s ease 0s;
		opacity: 0;
		visibility: hidden;
	}

	&.open {
		width: 350px;
		height: 450px;

		&:before {
			opacity: 0;
			visibility: hidden;
		}

		> header {
			flex-shrink: 0;

			> span {
				opacity: 1;
				visibility: visible;
			}

			&:hover {
				opacity: 1;
				cursor: default;
			}

			.close-chat {
				opacity: 1;
				visibility: visible;
				cursor: pointer;
			}
		}

		.intro,
		.feedback,
		footer {
			opacity: 1;
			visibility: visible;
			transition-delay: 0.4s;
		}
	}

	> header {
		color: $white;
		background-image: var(--primaryGradient);
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		padding: rem-calc(15);
		cursor: pointer;
		transition: all 0.2s;

		&:hover {
			opacity: 0.8;
		}

		> span {
			display: inline-block;
			margin-left: rem-calc(10);
			text-shadow: 0 1px 2px hsla(var(--black), 0.25);
		}

		.close-chat {
			position: absolute;
			top: 0;
			right: 0;
			padding: rem-calc(15);
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.intro {
		border-bottom: 1px solid var(--boxBorderColor);
		text-align: center;
		padding: rem-calc(10);
		font-weight: $global-weight-bold;
		color: var(--bodyColor);
		font-size: rem-calc(14);
		flex-shrink: 0;
	}

	.feedback {
		display: flex;
		flex-flow: column;
		overflow-y: auto;
		@include scrollbars(.5rem, var(--inputBorderColor), var(--boxBackground));
		flex-grow: 1;

		textarea {
			padding: rem-calc(10);
			border: 0;
			flex-grow: 1;
			margin: 0;
		}
	}

	footer {
		border-top: 1px solid var(--boxBorderColor);
		display: flex;
		flex-flow: column;
		flex-shrink: 0;

		.checkbox {
			margin: rem-calc(5 10);
		}

		button {
			display: block;
			width: 100%;
			padding: rem-calc(15);
			color: $white;
			background-image: var(--primaryGradient);
			text-shadow: 0 1px 2px hsla(var(--black), 0.25);
			font-weight: $global-weight-bold;
			cursor: pointer;
			white-space: nowrap;

			&:disabled {
				pointer-events: none;
				opacity: .5;
			}

			svg {
				margin-right: rem-calc(10);
			}
		}
	}
}
</style>
