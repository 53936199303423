<template lang="pug">
textarea(
	:value="modelValue"
	:placeholder="placeholder"
	:rows="rows"
	@input="resize"
)
</template>

<script>
export default {
	name: 'VAutoResizeTextarea',
	props: {
		modelValue: {
			type: String,
			required: false,
			default: null
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Type your message here..'
		},
		rows: {
			type: Number,
			required: false,
			default: 10
		}
	},
	emits: ['update:modelValue'],
	mounted() {
		this.$nextTick(() => {
			this.resize();
		});
	},
	methods: {
		resize() {
			this.$el.style.height = 'auto';
			this.$el.style.height = `${this.$el.scrollHeight + 2}px`;

			this.$emit('update:modelValue', this.$el.value);
		}
	}
};
</script>

<style lang="scss" scoped>
	textarea {
		overflow-y: hidden;
		resize: vertical;
	}
</style>
