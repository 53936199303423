<template lang="pug">
li.list-item(
	:class="{ disabled: isDisabled }"
	@click="isDisabled ? null : toggleCheck()"
	:title="isDisabled ? title : ''"
)
	.text-container
		slot
			.name(:title="title") {{ itemName }}
	fa-icon.icon(
		v-if="displayCheck"
		:icon="icon"
	)
</template>

<script>

export default {
	name: 'DropdownItem',
	props: {
		selected: {
			type: Boolean,
			required: true
		},
		item: {
			type: Object,
			required: true
		},
		labelProp: {
			type: String,
			required: false,
			default: 'name'
		},
		displayCheck: {
			type: Boolean,
			required: false,
			default: true
		},
		multiSelect: {
			type: Boolean,
			required: false,
			default: false
		},
		truncateName: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['change'],
	computed: {
		itemName() {
			if (this.truncateName) {
				return this.$text.truncateString(this.item[this.labelProp], 19);
			}
			return this.item[this.labelProp];
		},
		checkedIcon() {
			return this.multiSelect ? ['far', 'square-check'] : ['far', 'dot-circle'];
		},
		uncheckedIcon() {
			return this.multiSelect ? ['far', 'square'] : ['far', 'circle'];
		},
		icon() {
			return this.selected ? this.checkedIcon : this.uncheckedIcon;
		},
		isDisabled() {
			return !!this.item.isDisabled;
		},
		title() {
			return this.item[this.labelProp];
		}
	},
	methods: {
		toggleCheck() {
			this.$emit('change', this.item);
		}
	}
};
</script>

<style lang="scss" scoped>
.list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: var(--list-item-height);
	cursor: pointer;

	.text-container {
		display: flex;
		align-items: center;
		overflow: hidden;

		.name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	+ .list-item {
		border-top: 1px solid hsl(var(--white) / 20%);
	}

	.icon {
		flex-shrink: 0;
		margin-left: rem-calc(8);
	}

	&.disabled {
		color: hsl(var(--white) / 40%);
		cursor: not-allowed;
	}
}
</style>
