<template lang="pug">
div.dots
	span.dot
	span.dot
	span.dot
	span.dot
</template>

<script>
export default {
	name: 'LoadingDots'
};
</script>

<style lang="scss" scoped>
	.dots {
		display: flex;
		align-items: center;
		justify-content: center;

		@keyframes zoomDelay {
			0%, 80%, 100% { transform: scale(0) }
			40% { transform: scale(1) };
		}

		.dot {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-color: hsl(var(--black));
			animation: zoomDelay 1400ms infinite ease-in-out both;
			margin: rem-calc(0 3);

			&:nth-of-type(1) { background-color: hsl(198, 26%, 45%); animation-delay: -480ms; }
			&:nth-of-type(2) { background-color: hsl(198, 39%, 33%); animation-delay: -320ms; }
			&:nth-of-type(3) { background-color: hsl(198, 59%, 22%); animation-delay: -160ms; }
			&:nth-of-type(4) { background-color: hsl(197, 97%, 12%); animation-delay: 0ms; }
		}
	}
</style>
