<template lang="pug">
nav.tabs(:class="wrapperClass")
	button(
		class="tab-item"
		type="button"
		v-for="tab in tabs"
		:ref="tab.value"
		:key="tab.title"
		:class="[{ 'active' : tab.value === currentTab }, tab.value === currentTab && tabActiveClass ? tabActiveClass: '', tabClass]"
		:disabled="tab.disabled || false"
		@click="changeTabs(tab.value)"
	)
		span {{ tab.title }}
</template>

<script>
export default {
	name: 'VTabs',
	props: {
		currentTab: {
			type: String,
			required: true
		},
		tabs: {
			type: Array,
			required: true
		},
		noUrlChange: {
			type: Boolean,
			required: false,
			default: false
		},
		wrapperClass: {
			type: String,
			required: false,
			default: 'default-tabs'
		},
		tabClass: {
			type: String,
			required: false,
			default: 'default-tabs__item'
		},
		tabActiveClass: {
			type: String,
			required: false,
			default: 'default-tabs__item_active'
		}
	},
	emits: ['onClick'],
	mounted() {
		const tab = location.hash.replace('#', '');
		if (tab) {
			this.changeTabs(tab, true);
		}
	},
	methods: {
		changeTabs(value, stopReplace) {
			this.$emit('onClick', value);

			if (!stopReplace && !this.noUrlChange) {
				history.replaceState({ tab: value }, '', '#' + value);
			}

			this.newTab = value;
		}
	}
};
</script>

<style lang="scss">
	.default-tabs {
		position: relative;
		margin: 0 auto;

		.tab-item {
			display: inline-block;
			margin: 0 5px;
			padding: 10px;
			padding-bottom: 8px;
			font-size: 16px;
			letter-spacing: 0.8px;
			color: gray;
			text-decoration: none;
			border: none;
			background-color: transparent;
			border-bottom: 2px solid transparent;
			cursor: pointer;
			transition: all 0.25s;
			outline: none;

			&:hover {
				border-bottom-color: gray;
				color: var(--bodyColor);
			}

			&.active {
				border-bottom-color: white;
				color: var(--bodyColor);
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
</style>
