<template lang="pug">
#date-time-input
	DatePicker(
		v-model:value="dateTime"
		v-model:open="open"
		type="datetime"
		:lang="lang"
		:clearable="clearable"
		:placeholder="placeholder"
		:disabledDate="disabledDate"
		:disabledTime="disabledTime"
		:disabledCalendarChanger="disabledCalendarChanger"
		:input-class="{ 'mx-input': true, invalid: invalid }"
		@change="timeChanged"
		@close="close"
	)
</template>

<script>
/*
* Common component for picking a date and time using vue2-datepicker, documentation: https://github.com/mengxiong10/vue2-datepicker#vue2-datepicker
* props:
* modelValue - set with v-model attribute, the current value for this datetimepicker
* minDate - dates before this date will be disabled, no lower limit if null
* maxDate - dates after this date will be disabled no upper limit if null
* placeholder - text shown when no date is picked
* clearable - determines wether or not a clear-button is shown, setting the date to null
* invalid - visually presents that the picked date is invalid with a red border around the datepicker, does not change functionality otherwise
*/
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { DateTime } from 'luxon';

export default {
	name: 'VDateTimePicker',
	components: {
		DatePicker
	},
	props: {
		modelValue: {
			type: DateTime,
			required: false,
			default: null
		},
		minDate: {
			type: DateTime,
			required: false,
			default: null
		},
		maxDate: {
			type: DateTime,
			required: false,
			default: null
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Select date & time'
		},
		clearable: {
			type: Boolean,
			required: false,
			default: false
		},
		invalid: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			dateTime: this.luxonToFakeLocal(this.modelValue),
			open: false,
			lang: {
				formatLocale: {
					firstDayOfWeek: 1
				}
			}
		};
	},
	watch: {
		modelValue() {
			this.dateTime = this.luxonToFakeLocal(this.modelValue);
		}
	},
	methods: {
		// Because vue-datepicker displays the local timezone, we need to offset the luxon datetime to a fake local datetime
		luxonToFakeLocal(datetime) {
			const adjustedDate = datetime.setZone('local', { keepLocalTime: true });
			return adjustedDate.toJSDate();
		},
		fakeLocalToLuxon(date) {
			const fakeLocal = DateTime.fromJSDate(date);
			return fakeLocal.setZone(this.modelValue.zone, { keepLocalTime: true });
		},
		disabledDate(date) {
			const datetime = this.fakeLocalToLuxon(date);
			return (this.minDate && datetime < this.minDate.setZone(this.modelValue.zone).startOf('day')) ||
				(this.maxDate && datetime > this.maxDate.setZone(this.modelValue.zone).endOf('day'));
		},
		disabledTime(date) {
			const datetime = this.fakeLocalToLuxon(date);
			return (this.minDate && datetime < this.minDate) || (this.maxDate && datetime > this.maxDate);
		},
		disabledCalendarChanger(date, type) {
			if (type === 'year' || type === 'month') {
				return false;
			}
			return this.disabledDate(date);
		},
		close() {
			this.updated();
		},
		timeChanged(_, type) {
			if (!this.open || type === 'second') {
				this.open = false;
				this.updated();
			}
		},
		updated() {
			const luxonDate = this.fakeLocalToLuxon(this.dateTime);
			this.$emit('update:modelValue', luxonDate);
		}
	}
};
</script>

<style lang="scss" scoped>
#date-time-input {
	margin-bottom: 16px;
}
</style>
