<template lang="pug">
#tags-input
	VueTagsInput(
		v-model="newTag"
		:placeholder="placeholder"
		:tags="modelValue"
		:add-only-from-autocomplete="onlyAutocomplete"
		:allow-edit-tags="editableTags"
		:autocomplete-items="filteredAutocompleteTags"
		:max-tags="maxTags"
		:disabled="disabled"
		@tags-changed="newTags => $emit('update:modelValue', newTags)"
	)
</template>

<script>
import { VueTagsInput } from '@vojtechlanka/vue-tags-input';

export default {
	name: 'VTagsInput',
	components: {
		VueTagsInput
	},
	props: {
		modelValue: {
			type: Array,
			required: true
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Type a value and hit enter'
		},
		autocompleteTags: { // The tags need to be formatted as such: [{ text: <display_name>, id: <value> }]
			type: Array,
			required: false,
			default: () => []
		},
		onlyAutocomplete: {
			type: Boolean,
			required: false,
			default: false
		},
		editableTags: {
			type: Boolean,
			required: false,
			default: false
		},
		maxTags: {
			type: Number,
			required: false,
			default: 100
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			newTag: ''
		};
	},
	computed: {
		filteredAutocompleteTags() {
			if (!this.newTag) {
				return [];
			}
			return this.autocompleteTags.filter(tag => {
				if (!tag.text) {
					return 0;
				}
				return tag.text.toLowerCase().indexOf(this.newTag.toLowerCase()) !== -1;
			});
		}
	}
};
</script>
